
import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';
import getBlogs from '~/graphql/queries/getBlogs.graphql';

export default {
    mixins: [
        defaultFieldProps
    ],
    props: {
        header: {
            type: String,
            default: null
        },
        background: {
            type: String,
            default: 'default'
        },
        limit: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            cards: []
        };
    },
    fetchKey: 'FlexibleSectionsBLocksLatestBlogs',
    async fetch() {
        const site = this.$i18n.localeProperties.craftSiteHandle;
        const { data } = await this.$gql.executeQuery(
            getBlogs,
            { site, limit: this.limit }
        );

        if (data.entries) {
            this.cards = data.entries;
        }
    }
};
